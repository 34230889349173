#ppms {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 180px;
}

#ppms-sampleChamber {
  float: right;
  margin: 18px 10px;
  max-height: 300px;
  margin-block-start: 0.65em;
  margin-block-end: 0.65em;
}

#ppmsVSM {
  max-height: 300px;
  max-width: 130px;
  float: right;
  margin: 18px 10px;
  margin-block-start: 0.65em;
  margin-block-end: 0.65em;
}

#vsmOption {
  max-height: 225px;
  max-width: 130px;
  float: right;
  margin-block-start: 0.65em;
  margin-block-end: 0.65em;
}
