#mpms3 {
  position: relative;
  left: 57%;
  transform: translate(-50%, 0);
  max-height: 180px;
}

#sampleRod {
  float: right;
  max-height: 65vh;
  margin: auto 0 auto 5vw;
}

#vsmLookFor-container {
  display: flex;
}

#vsmLookFor-container h5 {
  margin: auto;
}

#vsm-container {
  margin: 10px 18px;
  position: relative;
}

#vsm {
  height: 200px;
  margin: auto;
}

#motorDot {
  height: 10px;
  width: 10px;
  position: absolute;
  z-index: 2;
  top: 90px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 100%;
  background-color: gold;
  animation: linearDrive 1.25s linear 2s infinite forwards;
}

@keyframes linearDrive {
  0% {
    top: 90px;
  }
  50% {
    top: 132px;
  }
  100% {
    top: 90px;
  }
}

@-webkit-keyframes linearDrive {
  0% {
    top: 90px;
  }
  50% {
    top: 132px;
  }
  100% {
    top: 90px;
  }
}

#mpms3Cutaway {
  width: 65vw;
  max-width: 340px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

#sampleRodCutoff {
  max-width: 90vw;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

#sampleProbe {
  max-height: 60vh;
  max-width: 50%;
  float: right;
  padding-left: 2px;
  margin-block-start: auto;
  margin-block-end: 16px;
}

#SQUID {
  margin: -17% 0;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0) rotate(90deg);
}
