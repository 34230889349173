body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#navMenu {
    height: 100%;
    max-width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-top: 60px;
    background-color: #fff;
    font-family: "Lato", sans-serif;
}

#navMenu i {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    margin-left: 50px;
    background-color: #78bd66;
    padding: 18px 18px 18px 18px;
    padding-left: 100%;
    cursor: pointer;
}

#navMenu a {
    padding: 8px 8px 8px 34px;
    text-decoration: none;
    font-size: 110%;
    display: block;
    color:#275da5;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-weight: bold;
    border: 2px solid #e4ebf4;
    border-top: none;
}


#navMenu a:first-of-type {
    border-top: 2px solid #e4ebf4;
}

#navMenu a:hover {
    background-color: #78bd66;
    color: #fff;
}

#navButton {
    padding: 9px 9px 0 9px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}
#homeTitle {
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.subTitle {
    -webkit-margin-before: 0;
            margin-block-start: 0;
    text-align: center;
}

#mrlLogo {
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%,0);
            transform: translate(-50%,0);
    -webkit-margin-before: 18px;
            margin-block-start: 18px;
}

#mpms3-Home {
    position: relative;
    top: 30%;
    -webkit-transform: translate(0,-30%);
            transform: translate(0,-30%);
}
#dilFridge {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 45vw;
  max-width: 240px;
}

#samplePuckMount {
  float: right;
  max-width: 22vw;
  margin: 18px 10px;
}

#dilFridgeDiagram {
  float: right;
  margin: 18px 0 18px 8px;
  width: 45vw;
  max-width: 200px;
}

#phaseDiagram {
  width: 75vw;
  max-width: 400px;
  margin: 0 20vw 0 5vw;
}

#ppms {
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  max-height: 180px;
}

#ppms-sampleChamber {
  float: right;
  margin: 18px 10px;
  max-height: 300px;
  -webkit-margin-before: 0.65em;
          margin-block-start: 0.65em;
  -webkit-margin-after: 0.65em;
          margin-block-end: 0.65em;
}

#ppmsVSM {
  max-height: 300px;
  max-width: 130px;
  float: right;
  margin: 18px 10px;
  -webkit-margin-before: 0.65em;
          margin-block-start: 0.65em;
  -webkit-margin-after: 0.65em;
          margin-block-end: 0.65em;
}

#vsmOption {
  max-height: 225px;
  max-width: 130px;
  float: right;
  -webkit-margin-before: 0.65em;
          margin-block-start: 0.65em;
  -webkit-margin-after: 0.65em;
          margin-block-end: 0.65em;
}

#mpms3 {
  position: relative;
  left: 57%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  max-height: 180px;
}

#sampleRod {
  float: right;
  max-height: 65vh;
  margin: auto 0 auto 5vw;
}

#vsmLookFor-container {
  display: -webkit-flex;
  display: flex;
}

#vsmLookFor-container h5 {
  margin: auto;
}

#vsm-container {
  margin: 10px 18px;
  position: relative;
}

#vsm {
  height: 200px;
  margin: auto;
}

#motorDot {
  height: 10px;
  width: 10px;
  position: absolute;
  z-index: 2;
  top: 90px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  border-radius: 100%;
  background-color: gold;
  -webkit-animation: linearDrive 1.25s linear 2s infinite forwards;
          animation: linearDrive 1.25s linear 2s infinite forwards;
}

@keyframes linearDrive {
  0% {
    top: 90px;
  }
  50% {
    top: 132px;
  }
  100% {
    top: 90px;
  }
}

@-webkit-keyframes linearDrive {
  0% {
    top: 90px;
  }
  50% {
    top: 132px;
  }
  100% {
    top: 90px;
  }
}

#mpms3Cutaway {
  width: 65vw;
  max-width: 340px;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

#sampleRodCutoff {
  max-width: 90vw;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

#sampleProbe {
  max-height: 60vh;
  max-width: 50%;
  float: right;
  padding-left: 2px;
  -webkit-margin-before: auto;
          margin-block-start: auto;
  -webkit-margin-after: 16px;
          margin-block-end: 16px;
}

#SQUID {
  margin: -17% 0;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0) rotate(90deg);
          transform: translate(-50%, 0) rotate(90deg);
}

.switch {
    position: absolute;
    top: 6px;
    right: 9px;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition:  .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #78bd66;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #78bd66;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  #ToggleLbl {
    position: absolute;
    top: 6px;
    right: 74px;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    font-weight: bold;
    font-size: 0.8em;
    width: 80px;
    text-align: center;
    overflow:  hidden;
}

  @media screen and (min-width: 520px) {
    .switch {
        right: unset;
        left: 450px;
    }

    #ToggleLbl {
      right: unset;
      left: 365px;
    }
}
html {
  background-color: #c4d5e8;
  color: #275da5;
}

#modal-root {
  position: relative;
  z-index: 999;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal-button {
  cursor: pointer;
}

.App {
  max-width: 520px;
}

header {
  background-color: #f7921e;
  height: 46px;
}

.contentTitle {
  text-align: center;
  text-transform: uppercase;
  -webkit-margin-before: 0.65em;
          margin-block-start: 0.65em;
  -webkit-margin-after: 0.65em;
          margin-block-end: 0.65em;
}

.contentContainer {
  background-color: #fff;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 36px;
  overflow: auto;
}

.contentContainer h4 {
  -webkit-margin-after: 0.65em;
          margin-block-end: 0.65em;
  -webkit-margin-before: 0.65em;
          margin-block-start: 0.65em;
}

.contentContainer content p {
  text-indent: 18px;
  -webkit-margin-before: 0.65em;
          margin-block-start: 0.65em;
  -webkit-margin-after: 0.65em;
          margin-block-end: 0.65em;
}

.contentContainer .linkContainer {
  text-indent: 0;
}

.contentContainer h5 {
  border: 2px solid #b7ddad;
  padding: 0px 12px;
}

.contentContainer table {
  width: 100%;
}

.linkContainer {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 9px;
  grid-column-gap: 9px;
}

.linkContainer a {
  width: 100%;
  font-family: consolas;
  font-size: 80%;
  text-align: center;
  border: 2px solid #b7ddad;
  padding: 8px 0;
  border-radius: 10px;
  position: relative;
}

.linkContainer div {
  text-align: center;
}

.linkContainer img {
  max-width: 95%;
}

.logoContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.85em 0;
}

.logo {
  margin: auto;
}

.papers-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 2px solid #b7ddad;
  -webkit-margin-before: 8px;
          margin-block-start: 8px;
  -webkit-margin-after: 8px;
          margin-block-end: 8px;
}

.papers-container a {
  padding: 8px;
  font-size: 2.2vh;
}

table {
  border: 2px solid #b7ddad;
  padding: 8px;
  -webkit-margin-after: 8px;
          margin-block-end: 8px;
}

th {
  font-size: 2.2vh;
  text-align: unset;
}

td:nth-child(odd) {
  color: black;
  padding-left: 10px;
  font-size: 2vh;
  width: 50%;
}

td:nth-child(even) {
  font-size: 2vh;
}

