html {
  background-color: #c4d5e8;
  color: #275da5;
}

#modal-root {
  position: relative;
  z-index: 999;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-button {
  cursor: pointer;
}

.App {
  max-width: 520px;
}

header {
  background-color: #f7921e;
  height: 46px;
}

.contentTitle {
  text-align: center;
  text-transform: uppercase;
  margin-block-start: 0.65em;
  margin-block-end: 0.65em;
}

.contentContainer {
  background-color: #fff;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 36px;
  overflow: auto;
}

.contentContainer h4 {
  margin-block-end: 0.65em;
  margin-block-start: 0.65em;
}

.contentContainer content p {
  text-indent: 18px;
  margin-block-start: 0.65em;
  margin-block-end: 0.65em;
}

.contentContainer .linkContainer {
  text-indent: 0;
}

.contentContainer h5 {
  border: 2px solid #b7ddad;
  padding: 0px 12px;
}

.contentContainer table {
  width: 100%;
}

.linkContainer {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 9px;
  grid-column-gap: 9px;
}

.linkContainer a {
  width: 100%;
  font-family: consolas;
  font-size: 80%;
  text-align: center;
  border: 2px solid #b7ddad;
  padding: 8px 0;
  border-radius: 10px;
  position: relative;
}

.linkContainer div {
  text-align: center;
}

.linkContainer img {
  max-width: 95%;
}

.logoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.85em 0;
}

.logo {
  margin: auto;
}

.papers-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #b7ddad;
  margin-block-start: 8px;
  margin-block-end: 8px;
}

.papers-container a {
  padding: 8px;
  font-size: 2.2vh;
}

table {
  border: 2px solid #b7ddad;
  padding: 8px;
  margin-block-end: 8px;
}

th {
  font-size: 2.2vh;
  text-align: unset;
}

td:nth-child(odd) {
  color: black;
  padding-left: 10px;
  font-size: 2vh;
  width: 50%;
}

td:nth-child(even) {
  font-size: 2vh;
}
