#navMenu {
    height: 100%;
    max-width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    background-color: #fff;
    font-family: "Lato", sans-serif;
}

#navMenu i {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    margin-left: 50px;
    background-color: #78bd66;
    padding: 18px 18px 18px 18px;
    padding-left: 100%;
    cursor: pointer;
}

#navMenu a {
    padding: 8px 8px 8px 34px;
    text-decoration: none;
    font-size: 110%;
    display: block;
    color:#275da5;
    transition: 0.3s;
    font-weight: bold;
    border: 2px solid #e4ebf4;
    border-top: none;
}


#navMenu a:first-of-type {
    border-top: 2px solid #e4ebf4;
}

#navMenu a:hover {
    background-color: #78bd66;
    color: #fff;
}

#navButton {
    padding: 9px 9px 0 9px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}