.switch {
    position: absolute;
    top: 6px;
    right: 9px;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition:  .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #78bd66;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #78bd66;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  #ToggleLbl {
    position: absolute;
    top: 6px;
    right: 74px;
    margin-block-end: 0;
    margin-block-start: 0;
    font-weight: bold;
    font-size: 0.8em;
    width: 80px;
    text-align: center;
    overflow:  hidden;
}

  @media screen and (min-width: 520px) {
    .switch {
        right: unset;
        left: 450px;
    }

    #ToggleLbl {
      right: unset;
      left: 365px;
    }
}