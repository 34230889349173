#dilFridge {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  width: 45vw;
  max-width: 240px;
}

#samplePuckMount {
  float: right;
  max-width: 22vw;
  margin: 18px 10px;
}

#dilFridgeDiagram {
  float: right;
  margin: 18px 0 18px 8px;
  width: 45vw;
  max-width: 200px;
}

#phaseDiagram {
  width: 75vw;
  max-width: 400px;
  margin: 0 20vw 0 5vw;
}
