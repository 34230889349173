#homeTitle {
    margin-block-end: 0;
}

.subTitle {
    margin-block-start: 0;
    text-align: center;
}

#mrlLogo {
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    margin-block-start: 18px;
}

#mpms3-Home {
    position: relative;
    top: 30%;
    transform: translate(0,-30%);
}